import React from 'react'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro/alt'
import HomeContentAlt from '../components/sections/home-content/alt'
import Partners from '../components/sections/partners'
import Existing from '../components/apply/existing'

import Surfers from '../images/surfers.png'

const HomeCOne = ({ location }) => {
  React.useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'landing',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Layout
      phone="866-740-2901"
      location={location}
      footerAlt
      includeCitySearch
      showCountDown
      skipPrepopQuestions
    >
      <SEO
        title="Medicare Supplement Plans - Medicare Companion"
        keywords={[`medicare`, `insurance`, `plans`, `affordable`, `supplement`]}
      />
      <Header
        phone="866-740-2901"
        className="new gray-style"
        grayLogo
        ctaText="<strong>Need Help?</strong> Speak with a <br/> Licensed Insurance Agent."
      />
      <Intro
        showTitle
        classNames="reverse"
        title="Get Additional Medicare Coverage"
        subTitle="Get a <i>FREE</i> quote in less than 2 minutes for better coverage."
        bgImage={Surfers}
        showImgContent
      >
        <div className="form-box">
          <div className="contacts-form alt text-center">
            <Existing
              toggleApplyLayout
              landing
              type="medicareAlt"
              className="home-inline"
              title="Are you currently enrolled <br />in Medicare parts A & B"
              includeCitySearch
              popover
              skipPrepopQuestions
            />
            <ul className="security-list pt3">
              <li>
                <img src="/images/Norton-Logo.png" alt="description of thing" />
              </li>
              <li>
                <img src="/images/McAfee-Logo.png" alt="description of thing" />
              </li>
              <li>
                <img src="/images/BBB-Logo.png" alt="description of thing" />
              </li>
            </ul>
          </div>
        </div>
      </Intro>
      <main id="main">
        <Partners showTitle={false} bg="bg-dark" />
        <HomeContentAlt phone="866-740-2901" />
      </main>
    </Layout>
  )
}

export default HomeCOne
